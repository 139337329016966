(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'NewAccountDetailsModalCtrl';

    angular.module('ecog.dashboard')
        .controller(controllerId, ['$log', '$rootScope', '$modalInstance', message]);

    function message($log, $rootScope, $modalInstance) {
      var vm = this;

      vm.close = close;

      function close() {
        $modalInstance.dismiss();
      }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/checkout/modal/newaccountdetails.js","/components/checkout/modal")