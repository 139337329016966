(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'CheckoutCtrl';

    angular.module('ecog.checkout')
        .controller(controllerId, ['$log', '$scope', '$rootScope', '$location', '$cookies', '$modal', '$http', 'ACTION_URL', 'user', 'paypal', 'loadingService', 'usersService', 'addresses', checkout]);

    function checkout($log, $scope, $rootScope, $location, $cookies, $modal, $http, ACTION_URL, user, paypal, loadingService, usersService, addresses) {

        var vm = this;

        vm.isSubmitting = false;
        vm.errors = [];
        vm.stage = 1;
        vm.item = {};
        vm.user = user;
        vm.addresses = addresses;
        vm.countries = [];

        vm.totals = {};

        vm.getTotals = getTotals;

        vm.item.deliveryid = null;
        vm.item.billingid = null;

        vm.nodelivery = true;
        vm.nobilling = true;

        if (vm.user.vatnumber) {
          vm.item.vatnumber = vm.user.vatnumber;
        }

        angular.forEach(vm.addresses, function (value, key) {

          var longname = value.name + ", " + value.address1;
          longname += value.address2 && value.address2.length > 0 ? ", " + value.address2 : "";
          longname += value.city && value.city.length > 0 ? ", " + value.city : "";
          longname += value.county && value.county.length > 0 ? ", " + value.county : "";
          longname += value.postcode && value.postcode.length > 0 ? ", " + value.postcode : "";
          longname += value.country && value.country.length > 0 ? ", " + value.country : "";
          value.longname = longname;
          if (value.type === 1) {
            vm.nobilling = false;
            if (vm.user.billingid === value.id) {
              vm.item.billingid = value.id.toString();
              vm.item.billingname = value.name;
              vm.item.billing_address1 = value.address1;
              vm.item.billing_address2 = value.address2;
              vm.item.billing_city = value.city;
              vm.item.billing_county = value.county;
              vm.item.billing_postcode = value.postcode;
              vm.item.billing_country = value.country;
            }
          } else if (value.type === 2) {
            vm.nodelivery = false;
            if (vm.user.deliveryid === value.id) {
              vm.item.deliveryid = value.id.toString();
              vm.item.deliveryname = value.name;
              vm.item.delivery_address1 = value.address1;
              vm.item.delivery_address2 = value.address2;
              vm.item.delivery_city = value.city;
              vm.item.delivery_county = value.county;
              vm.item.delivery_postcode = value.postcode;
              vm.item.delivery_country = value.country;
              vm.item.deliverycontact = value.contactnumber;
            }
          }
        });

        if (vm.nobilling) {
          vm.item.billingname = user.businessname;
          vm.item.billing_address1 = user.address1;
          vm.item.billing_address2 = user.address2;
          vm.item.billing_city = user.city;
          vm.item.billing_county = user.county;
          vm.item.billing_postcode = user.postcode;
          vm.item.billing_country = user.country;
        }

        if (vm.nodelivery) {
          vm.item.deliveryname = user.businessname;
          vm.item.delivery_address1 = user.address1;
          vm.item.delivery_address2 = user.address2;
          vm.item.delivery_city = user.city;
          vm.item.delivery_county = user.county;
          vm.item.delivery_postcode = user.postcode;
          vm.item.delivery_country = user.country;
          vm.item.deliverycontact = user.phone;
        }

        //vm.item.saveaddress = true;
        vm.item.savedelivery = true;
        vm.item.savebilling = true;

        vm.discountcode = undefined;
        vm.discounterrors = [];


        if ($rootScope.currentUser.credit > 0) {
          vm.useCredit = true;
        }
        vm.useragreement = false;
        vm.customerDeliveryPrice = 0.00;

        vm.changebilling = changebilling;
        vm.changedelivery = changedelivery;

        vm.next = next;
        vm.back = back;
        vm.submit = submit;
        vm.applyDiscount = applyDiscount;
        vm.removeDiscount = removeDiscount;

        vm.item.deliverymethod = '0';
        vm.item.additionaldelivery = user.additionaldelivery === "1" || user.additionaldelivery === 1 ? true : false;
        vm.onaccount = user.onaccount === 1 ? true : false;
        vm.paypal = {};
        vm.paypal.form_url = paypal.form_url;
        vm.paypal.payer_email = paypal.payer_email;
        vm.viewuseragreement = viewuseragreement;

        function viewuseragreement() {
          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'views/components/misc/useragreement.html?update=201702101030&ek=3',
            controller: 'UserAgreementModalCtrl',
            controllerAs: 'vm',
            size: 'md'
          });

          modalInstance.result.then(function () {
            vm.useragreement = true;
          }, function () {

          });
        }

        /*$scope.$watch('vm.item', function () {
          if (vm.deliveryAsBilling) {
            copyBilling();
          }
        }, true);*/

        function changebilling() {
          if (vm.item.billingid) {
            angular.forEach(vm.addresses, function (value, key) {
              if (value.id.toString() === vm.item.billingid.toString()) {
                vm.item.billingname = value.name;
                vm.item.billing_address1 = value.address1;
                vm.item.billing_address2 = value.address2;
                vm.item.billing_city = value.city;
                vm.item.billing_county = value.county;
                vm.item.billing_postcode = value.postcode;
                vm.item.billing_country = value.country;
              }
            });
          } else {
            vm.item.billingname = vm.user.businessname;
            vm.item.billing_address1 = '';
            vm.item.billing_address2 = '';
            vm.item.billing_city = '';
            vm.item.billing_county = '';
            vm.item.billing_postcode = '';
            vm.item.billing_country = '';
          }
        }

        function changedelivery() {
          if (vm.item.deliveryid) {
            angular.forEach(vm.addresses, function (value, key) {
              if (value.id.toString() === vm.item.deliveryid.toString()) {
                vm.item.deliveryname = value.name;
                vm.item.delivery_address1 = value.address1;
                vm.item.delivery_address2 = value.address2;
                vm.item.delivery_city = value.city;
                vm.item.delivery_county = value.county;
                vm.item.delivery_postcode = value.postcode;
                vm.item.delivery_country = value.country;
                vm.item.deliverycontact = value.contactnumber;
              }
            });
          } else {
            vm.item.deliveryname = vm.user.businessname;
            vm.item.delivery_address1 = '';
            vm.item.delivery_address2 = '';
            vm.item.delivery_city = '';
            vm.item.delivery_county = '';
            vm.item.delivery_postcode = '';
            vm.item.delivery_country = '';
            vm.item.deliverycontact = '';
          }

        }

        function next() {
          vm.stage++;
          if (vm.stage === 3) {
            $rootScope.getTotals();
          }
          if (vm.stage === 3 && !user.offerNextDay) {
            vm.stage++;
          } else if (vm.stage === 4) {
            getTotals();
          }
        }

        function back() {
          vm.stage--;
          if (vm.stage === 3 && !user.offerNextDay) {
            vm.stage--;
          }
        }

        init();

        function submit() {

          vm.isSubmitting = true;
          loadingService.start('checkoutOverlay', 'Submitting Order')

          var items = [];

          angular.forEach($rootScope.basket, function (value) {
            items.push({ code: value.code, qty: value.qty, name: value.name });
          });

          vm.item.items = items;
          vm.item.discountcode = vm.discountcode;

          if (vm.useCredit) {
            vm.item.useCredit = true;
          }

          vm.item.prioritydelivery = vm.item.deliverymethod === '1' ? true : false;

          $http.post(ACTION_URL + "checkout.php", vm.item).then(function(data, status) {
            vm.message = data.data;
            if (data.data.message !== undefined && data.data.message === "SUCCESS") {
              vm.order = data.data.order;
              switch (parseInt(vm.item.paymentmethod)) {
                case 1:
                  // paypal
                  /*vm.paypal.returnaddress = "http://jrwholesale.ecognitiv.co.uk/";
                  vm.paypal.orderno = data.order.orderno;
                  vm.paypal.itemname = data.order.ordername;
                  vm.paypal.total = data.order.total.toFixed(2);*/
                  var paypal_form = document.forms["paypal_form"];
                  paypal_form.return.value = "http://portal.jrpetproducts.com/";
                  paypal_form.business.value = vm.paypal.payer_email;
                  paypal_form.invoice.value = 'WEB' + data.data.order.orderno;
                  paypal_form.item_name_1.value = data.data.order.ordername;
                  paypal_form.amount_1.value = data.data.order.total.toFixed(2);
                  document.forms["paypal_form"].submit();
                  vm.stage = 6;
                  $rootScope.basket = {};
                  break;
                case 2:
                case 4:
                case 3:
                  vm.stage = 5;
                  newaccountMessage();
                  $rootScope.basket = {};
                break;
                case 9: {
                  var orderData = data.data.order;
                  vm.item.orderNum = vm.order.orderno;
                  $http.post(ACTION_URL + "epdqinfo.php", vm.item).then(function(epdqdata, status) {
                    if (epdqdata.data && epdqdata.data.hash) {
                      var barclays_form = document.forms["barclays_form"];

                      barclays_form.AMOUNT.value = epdqdata.data.total;
                      barclays_form.ORDERID.value = orderData.orderno;
                      barclays_form.SHASIGN.value = epdqdata.data.hash;
                      barclays_form.PSPID.value = epdqdata.data.pspid;
                      document.forms["barclays_form"].submit();
                      vm.stage = 6;

                    } else {
                      vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
                      vm.isSubmitting = false;
                      loadingService.stop("checkoutOverlay");
                    }

                  }, function (error) {

                    vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
                    vm.isSubmitting = false;
                    loadingService.stop("checkoutOverlay");
                  }).finally(function () {


                  });

                  break;
                }
              }

            } else if (data.data.errors) {
              vm.errors = data.data.errors;
            } else {
              vm.errors = ['There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.']
            }

            vm.isSubmitting = false;
          }, function (error) {

            vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
          }).finally(function () {
            vm.isSubmitting = false;
            loadingService.stop("checkoutOverlay");
          });
        }

        function copyBilling () {
          vm.item.delivery_address1 = vm.item.billing_address1;
          vm.item.delivery_address2 = vm.item.billing_address2;
          vm.item.delivery_city = vm.item.billing_city;
          vm.item.delivery_county = vm.item.billing_county;
          vm.item.delivery_postcode = vm.item.billing_postcode;
          vm.item.delivery_country = vm.item.billing_country;
        }

        function removeDiscount() {
          vm.discountcode = undefined;
          getTotals();
        }

        function applyDiscount() {

          vm.submittingCode = true;
          vm.discounterrors = [];
          vm.discountmessage = "";

          $http.post(ACTION_URL + "applydiscount.php", {discountcode: vm.enterDiscount}).then(function(data, status) {
            if (data.data.message !== undefined && data.data.message !== "ERROR") {
              vm.submittingCode = false;
              vm.discountcode = data.data.code.code;
              vm.discountmessage = data.data.code.name;
              vm.enterDiscount = undefined;
              vm.addingDiscount = false;
              getTotals();
            } else {
              if (data.data.message !== undefined && data.data.message === "ERROR") {
                vm.discounterrors = data.data.errors;
                vm.submittingCode = false;
              } else {
                vm.discounterrors = ['Unforseen error, could not apply discount. If problem persists, please contact us.'];
                vm.submittingCode = false;
              }
            }
          }, function (error) {
            vm.discounterrors = ['Unforseen error, could not apply discount. If problem persists, please contact us.'];
            vm.submittingCode = false;
          });
        }

        function init() {
          // get totals..
          getCountries();
          getTotals();
          getDeliveryPrice();
        }

        function getTotals() {

          var items = [];

          angular.forEach($rootScope.basket, function (value) {
            items.push({ code: value.code, qty: value.qty, name: value.name });
          });

          var priorityDelivery = false;

          if (vm.item.deliverymethod === '1') {
            priorityDelivery = true;
          }

          $http.post(ACTION_URL + "baskettotals.php", {items:items, discountcode: vm.discountcode, prioritydelivery: priorityDelivery, useCredit: vm.useCredit}).success(function(data, status) {
            if (data.message !== undefined && data.message === "SUCCESS") {
              vm.totals = data.totals;
              angular.forEach($rootScope.basket, function (value) {

                if (vm.totals.items[value.code]) {
                  var productTotals = vm.totals.items[value.code];
                  value.price = productTotals.priceper;
                  value.unavailable = productTotals.unavailable;
                }
              });
            }
          });
        }

        function getDeliveryPrice() {
          $http.post(ACTION_URL + "deliveryprice.php", {}).success(function(data, status) {
            if (data.message !== undefined && data.message === "SUCCESS") {
              vm.customerDeliveryPrice = data.deliveryprice;
            }
          });
        }

        function getCountries() {
          usersService.countries().then(function(countries) {
            vm.countries = countries;
          });
        }

        function newaccountMessage() {
          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'views/components/checkout/modal/newaccountdetails.html?update=' + FILEDATES['views/components/checkout/modal/newaccountdetails.html'] || '',
            controller: 'NewAccountDetailsModalCtrl',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
            }
          });

          modalInstance.result.then(function () {

          }, function () {

          });
        }


    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/checkout/checkout.js","/components/checkout")