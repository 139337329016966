(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'JoinWaitlistModalCtrl';

    angular.module('ecog.products')
        .controller(controllerId, ['$log', '$rootScope', '$modalInstance', 'item', '$timeout', '$window', '$location', '$http', 'ACTION_URL', waitlist]);

    function waitlist($log, $rootScope, $modalInstance, item, $timeout, $window, $location, $http, ACTION_URL) {
      var vm = this;

      vm.product = item;

      vm.userEmail = $rootScope.currentUser.email || "";

      vm.isSubmitting = false;
      vm.complete = false;
      vm.errors = [];

      vm.close = close;
      vm.submit = submit;

      console.log(vm.product);

      function close() {
        $modalInstance.dismiss();
      }

      function submit() {
        vm.isSubmitting = true;



        var productId = vm.product.selectedSub || vm.product.id;

        $http.post(ACTION_URL + "joinwaitlist.php", {'productid': productId, 'email': vm.userEmail}).then(function(data, status) {
          if (data.data.message !== undefined && data.data.message === "SUCCESS") {
            vm.complete = true;
          } else if (data.data.errors) {
            vm.isSubmitting = false;
            vm.errors = data.data.errors;
          } else {
            vm.isSubmitting = false;
            vm.errors = [ 'There was an unforseen error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.<br>' + data.data.message ];
          }

        }, function (error) {
          vm.errors = [ 'There was an unforseen server error, please refresh your browser and try again. If problem persists please <a href="#/contact">contact us</a>.' ];
        }).finally(function () {
          vm.isSubmitting = false;
        });
      }


    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/products/modal/joinwaitlist.js","/components/products/modal")