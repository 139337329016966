(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function () {
    'use strict';

    var controllerId = 'PalletDealsModalCtrl';

    angular.module('ecog.dashboard')
        .controller(controllerId, ['$log', '$rootScope', '$modalInstance', '$location', message]);

    function message($log, $rootScope, $modalInstance, $location) {
      var vm = this;

      vm.close = close;
      vm.clickPalletDeals = clickPalletDeals;

      $rootScope.shownPi = 1;

      function clickPalletDeals() {
        $location.url('/products/' + PALLETCATEGORY);
        $modalInstance.dismiss();
        
      }

      function close() {
        $modalInstance.dismiss();
        $rootScope.shownPi = 1;
      }

    }
})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/dashboard/modal/palletdeals.js","/components/dashboard/modal")