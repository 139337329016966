(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
(function(){

  angular
    .module('ecog.usercheck')
    .factory('userCheckService', userCheckService);

  userCheckService.$inject = ['$log', '$rootScope', '$http', 'ACTION_URL', 'productsService'];

  function userCheckService ($log, $rootScope, $http, ACTION_URL, productsService) {

    var service = {
      check: check,
      logout: logout
    };

    return service;

    ////////////

    function check() {
      $http.post(ACTION_URL + "loggedin.php").success(function(data, status) {
        if (data.message !== undefined && data.message === "SUCCESS") {
          $rootScope.currentUser = data.userObj;
          $rootScope.pricelist = data.priceObj;
          if (data.userObj.overrideminimumorder) {
            $rootScope.orderLimit = parseFloat(data.userObj.minimumorder);
          } else {
            $rootScope.orderLimit = data.userObj.type === "1" || data.userObj.type === 1 ? 250.00 : 1000.00;
          }
          productsService.getPromotions()
            .then(function (data) {
              $rootScope.promotions = data;
            });

          productsService.getSpotlights()
            .then(function (data) {
              $rootScope.spotlights = data;
            });

          if (!$rootScope.fetchedfrequentproducts) {
            productsService.getFrequent()
              .then(function (data) {
                $rootScope.frequent = data;
                $rootScope.fetchedfrequentproducts = true;
              });
          }

        } else {
          $rootScope.currentUser = undefined;
          $rootScope.pricelist = undefined;
          $rootScope.orderLimit = 250.00;
        }
      });
    }

    function logout() {
      $http.post(ACTION_URL + "logout.php").success(function(data, status) {
        if (data.message !== undefined && data.message === "SUCCESS") {
          $rootScope.currentUser = undefined;
          $rootScope.pricelist = undefined;
          $rootScope.orderLimit = 250.00;
        }
      });
    }
  }

})();

}).call(this,require("/NPYxj"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/usercheck/usercheck.service.js","/components/usercheck")